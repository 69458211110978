import { useContext } from 'react';
import styled from 'styled-components';

import CancelButton from 'Shared/Warehouse/CancelButton';
import {
   isGroupWithConstructionAndPolesPalette,
   packingPlaces,
} from 'Shared/Warehouse/OrderPartsGroup';
import { TYPE_CONSTRUCTION_AND_POLES_PALETTE } from 'Shared/Warehouse/Palette';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import { Context } from './Context';
import FinalPalette from './FinalPalette';
import PlanPalettesAgainButton from './PlanPalettesAgainButton';
import ToBeSentButton from './ToBeSentButton';

const Group = ({ group }) => {
   const { refetch } = useContext(Context);
   const groupWithConstructionAndPolesPalette = isGroupWithConstructionAndPolesPalette(group);
   const palettes = group.palettes.map((p, i) => {
      const next = group.palettes[i + 1];
      const nextPaletteIsConstructionAndPoles =
         next && next.type === TYPE_CONSTRUCTION_AND_POLES_PALETTE;
      return (
         <FinalPalette
            key={p.id}
            finalPalette={p}
            groupWithConstructionAndPolesPalette={groupWithConstructionAndPolesPalette}
            nextPaletteIsConstructionAndPoles={nextPaletteIsConstructionAndPoles}
         />
      );
   });

   return (
      <tr>
         <td>
            <Ul>
               <Li>Id grupy: {group.publicId}</Li>
               <Li>
                  <CompanyName
                     orderPartsGroup={group}
                     createdByExternalClient={group.createdByExternalClient}
                  />
               </Li>
               <Li>Gdzie: {packingPlaces[group.packingPlace]}</Li>
               <Li>Spakowano: {group.packedAt}</Li>
            </Ul>
         </td>
         <td>{palettes}</td>
         <td>
            <CancelButton group={group} refetch={refetch} />
            <PlanPalettesAgainButton group={group} />
            <ToBeSentButton group={group} />
         </td>
      </tr>
   );
};

export default Group;

const Ul = styled.ul`
   padding-left: 0rem;
`;

const Li = styled.li`
   list-style: none;
`;
