import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import {
   attribute,
   guessPaletteType,
   shouldRenderProductsInPaletteConstructionAndPoles,
} from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

import PlannedToTransport from './PlannedToTransport';

const renderProducts = (
   parts,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
) => {
   if (
      shouldRenderProductsInPaletteConstructionAndPoles(
         groupWithConstructionAndPolesPalette,
         nextPaletteIsConstructionAndPoles,
      )
   ) {
      const products = parts.map((op) => {
         return <Products key={op.id} products={op.products} />;
      });
      return products;
   }
   return 'Paleta słupów';
};

const FinalPalette = ({
   finalPalette,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
}) => {
   const paletteType = guessPaletteType(
      groupWithConstructionAndPolesPalette,
      finalPalette.type,
      nextPaletteIsConstructionAndPoles,
   );
   const ordersPublicIds = finalPalette.orderParts.map((op) => {
      return (
         <li key={op.id}>
            <ZamowieniaLink
               displayId={op.order.zamowieniaId}
               zamowieniaId={op.order.zamowieniaId}
            />
         </li>
      );
   });
   const products = renderProducts(
      finalPalette.orderParts,
      groupWithConstructionAndPolesPalette,
      nextPaletteIsConstructionAndPoles,
   );
   return (
      <table className="table table-hover table-sm">
         <thead>
            <tr>
               <th>Część: {finalPalette.publicId || ''}</th>
               <th>Pakowane</th>
               <th>Planowanie Transportu</th>
               <th>Zamówienia / części zamówień</th>
               <th>Produkty</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>
                  <Ul>
                     <Li>Szerokość: {attribute('width', finalPalette.attributes)}</Li>
                     <Li>Wysokość: {attribute('height', finalPalette.attributes)}</Li>
                     <Li>Długość: {attribute('length', finalPalette.attributes)}</Li>
                     <Li>Waga: {attribute('weight', finalPalette.attributes)}</Li>
                     {groupWithConstructionAndPolesPalette &&
                        !nextPaletteIsConstructionAndPoles && (
                           <Li>{attribute('numberOfPoles', finalPalette.attributes)}</Li>
                        )}
                     <Li>Typ palety: {paletteType}</Li>
                  </Ul>
               </td>
               <td>
                  <Ul>
                     <Li>Przez: {finalPalette.packedByFilledInManually || ''}</Li>
                     <Li>Kiedy: {finalPalette.packedAt || ''}</Li>
                  </Ul>
               </td>
               <td>
                  <PlannedToTransport finalPalette />
               </td>
               <td>{ordersPublicIds} </td>
               <td>{products}</td>
            </tr>
         </tbody>
      </table>
   );
};

export default FinalPalette;

const Ul = styled.ul`
   padding-left: 0rem;
`;

const Li = styled.li`
   list-style: none;
`;
