import axios from 'axios';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import Calendar from 'react-calendar';

import CloseCalendarButton from 'Shared/CloseCalendarButton';

const PlannedToTransport = ({ finalPalette }) => {
   const { submitForm } = useFormikContext();
   const [show, setShow] = useState(false);
   const [date, setDate] = useState(finalPalette?.sendingPlannedAt);
   const sendingPlannedAt = finalPalette?.sendingPlannedAt;

   const onClick = () => {
      setShow(true);
   };

   const planTransport = async (data) => {
      await axios.put(`/warehouse/final-palettes/${finalPalette.id}/sending-planned-at`, {
         data,
      });
      setShow(false);
      submitForm();
   };

   const onChange = (d) => {
      const formattedDate = dayjs(d).format('YYYY-MM-DD');
      setDate(formattedDate);
   };

   return (
      <div>
         {sendingPlannedAt &&
            (sendingPlannedAt === '1000-01-01' ? (
               <div>Transport bez daty</div>
            ) : (
               <div>
                  Transport zaplanowany na:
                  <br />
                  <b>{sendingPlannedAt}</b>
               </div>
            ))}
         {!show && (
            <button className="btn btn-success" onClick={() => onClick()} type="button">
               {sendingPlannedAt ? 'Rozplanuj ponownie' : 'Planowanie transportu'}
            </button>
         )}
         {show && (
            <div>
               <CloseCalendarButton setShow={setShow} />
               <Calendar onChange={onChange} className="sm" locale="pl" />
               <button
                  className="btn btn-success"
                  onClick={() => planTransport(date)}
                  type="button"
               >
                  Zaplanuj z datą
               </button>
               <button
                  className="btn btn-warning"
                  onClick={() => planTransport('1000-01-01')}
                  type="button"
               >
                  Zaplanuj bez daty
               </button>
               <button className="btn btn-danger" onClick={() => planTransport(null)} type="button">
                  Anuluj transport
               </button>
            </div>
         )}
      </div>
   );
};

export default PlannedToTransport;
